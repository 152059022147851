import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { sendLog } from 'helpers/log'
import { libraryConst } from 'constants/common'
import { search } from 'services/content'
import { getCookie, isGuestMode } from 'helpers/cookie'
import { generateShortUuid } from 'helpers/generate/uuid'
import { getSessionStorage } from 'helpers/session-storage'
import { getPartnerToken } from 'helpers/partner'
import apiConfig from 'configs/api'

export async function getBookingDetail(bookingId: string | string[]) {
  try {
    return apiClient({
      url: isGuestMode()
        ? `/content/guest/v3/booking/${bookingId}`
        : `/content/v3/booking/${bookingId}`,
      method: 'GET',
      ...(!isGuestMode() ? { token: getIdToken() } : {}),
      headers: getCookie('book_token') && {
        'Booking-Access-Token': getCookie('book_token'),
      },
    })
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function getBookingList({ status, pageParam }) {
  try {
    const { data } = await apiClient({
      method: 'GET',
      url: '/content/guest/v1/booking',
      params: {
        event_status: status,
        limit: 10,
        cursor: pageParam,
      },
      headers: {
        'Booking-Access-Token': getCookie('book_token'),
      },
    })
    return data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function createEventBooking(payload) {
  try {
    const { data } = await apiClient({
      method: 'POST',
      url: isGuestMode()
        ? '/content/guest/v1/event/book'
        : '/content/v1/event/book',
      data: payload,
      headers: {
        'Idempotent-Key': generateShortUuid(),
        ...(getPartnerToken() && {
          'Partner-Token': getPartnerToken(),
          Channel: apiConfig.channelPartner,
        }),
      },
      token: getIdToken(),
    })
    return data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function cancelEventBooking(book_session_id) {
  try {
    return apiClient({
      method: 'DELETE',
      url: isGuestMode()
        ? `/content/guest/v1/event/book/${book_session_id}`
        : `/content/v1/event/book/${book_session_id}`,
      token: getIdToken(),
      headers: {
        ...(getPartnerToken() && {
          'Partner-Token': getPartnerToken(),
          Channel: apiConfig.channelPartner,
        }),
      },
    })
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function saveAdditionalInfo({
  book_session_id,
  additional_info,
  send_to_participant_email,
}) {
  try {
    const res = await apiClient({
      method: 'PUT',
      url: isGuestMode()
        ? `/content/guest/v1/event/book/${book_session_id}/additional-info`
        : `/content/v1/event/book/${book_session_id}/additional-info`,
      data: { additional_info, send_to_participant_email },
      token: getIdToken(),
      headers: {
        ...(getPartnerToken() && {
          'Partner-Token': getPartnerToken(),
          Channel: apiConfig.channelPartner,
        }),
      },
    })
    return res.data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function getEventBookingDetail(book_session_id) {
  try {
    const { data } = await apiClient({
      method: 'GET',
      url: isGuestMode()
        ? `/content/guest/v1/event/book/${book_session_id}`
        : `/content/v1/event/book/${book_session_id}`,
      token: getIdToken(),
    })
    return data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function getCreatorEventList({
  creator_id = '',
  page = 1,
  sort_by = 'create_date',
  sort_direction = 'DESC',
  eventFilterStatus = ['upcoming'],
}) {
  let res = await apiClient({
    url: `/content/guest/v1/creator/${creator_id}/product/list${
      eventFilterStatus.length
        ? eventFilterStatus
            .map(
              (filterItem, i) =>
                `${i === 0 ? '?' : ''}event_status=${filterItem}`,
            )
            .join('&')
        : ''
    }`,
    method: 'GET',
    params: {
      limit: 5,
      page: page,
      sort_by: sort_by,
      sort: sort_direction,
      is_published: true,
      type: 'event',
      status: 1,
    },
  })

  return res.data
}

export const fetchPurchasedEvents = async ({
  eventStatus,
  pageParam = '',
  ...payload
}) => {
  try {
    const res = await apiClient({
      method: 'GET',
      url: '/content/v3/product/event/purchased',
      token: getIdToken(),
      params: {
        limit: libraryConst.defaultLimit,
        event_status: eventStatus,
        cursor: pageParam,
        ...payload,
      },
    })
    return res.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

type TypeEventFilters = 'upcoming' | 'event_ended'

export async function getEventList({
  page = 1,
  limit = 10,
  filter = null,
}: {
  page?: number
  limit?: number
  filter?: TypeEventFilters[]
}) {
  try {
    return search({
      le: limit,
      pe: page,
      tab: 'event',
      filter_status: filter,
    })
  } catch (err) {
    sendLog(err)
  }
}

export const getEventDetail = async (
  product_uid: string | string[],
  partnerToken?: string | string[],
) => {
  try {
    return await apiClient({
      url: !isGuestMode()
        ? `/content/v2/product/${product_uid}/detail`
        : `/content/guest/v2/product/${product_uid}/detail`,
      method: 'GET',
      token: isGuestMode() ? null : getIdToken(),
      headers: {
        ...(partnerToken && {
          'Partner-Token': partnerToken,
          Channel: apiConfig.channelPartner,
        }),
      },
    })
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export const getProductEventDetail = async (
  product_uid: string | string[],
  pt?: string | string[],
  partnerToken?: string | string[],
) => {
  try {
    return await apiClient({
      url: !isGuestMode()
        ? `/content/v1/product/${product_uid}/event`
        : `/content/guest/v1/product/${product_uid}/event`,
      method: 'GET',
      token: isGuestMode() ? null : getIdToken(),
      params: {
        pt: pt,
      },
      headers: {
        ...(partnerToken && {
          'Partner-Token': partnerToken,
          Channel: apiConfig.channelPartner,
        }),
      },
    })
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export const getGuestEventBookingStatus = async (
  product_uid: string | string[],
) => {
  try {
    const { data } = await apiClient({
      url: `/order/guest/v1/booking/${product_uid}/status`,
      method: 'GET',
      headers: { 'Idempotent-Key': generateShortUuid() },
    })
    return data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export const getSeatLayout = async (product_event_uid: string) => {
  return await apiClient({
    url: `/content/v1/product/${product_event_uid}/seat`,
    method: 'GET',
    token: getIdToken(),
  })
}

export const checkEventAvailability = async (product) => {
  try {
    return await apiClient({
      url: !isGuestMode()
        ? `/content/v1/product/purchasable`
        : `/content/guest/v1/product/purchasable`,
      method: 'POST',
      token: getIdToken(),
      headers: {
        ...(getPartnerToken() && {
          'Partner-Token': getPartnerToken(),
          Channel: apiConfig.channelPartner,
        }),
      },
      data: {
        products: [product],
      },
    })
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getEventTypes = async () => {
  return await apiClient({
    url: '/content/guest/v1/product/event_types',
    method: 'GET',
  })
}

export const getEventTypesForHomePage = async () => {
  return await apiClient({
    url: '/recommendation/guest/v1/homepage/event_types',
    method: 'GET',
  })
}

export interface IEventRecommendationParams {
  page?: number
  limit?: number
  event_types?: string
  cat_id?: string[] | string
  event_status?: string
  content_type?: string[] | string
  etid?: string[] | string
  province?: string[] | string
}

export const getEventRecommendation = async ({
  cat_id,
  page,
  limit,
  event_status,
  etid,
  content_type,
  province,
}: IEventRecommendationParams) => {
  let url = !isGuestMode()
    ? `/recommendation/v1/event?`
    : `/recommendation/guest/v1/event?`

  return await apiClient({
    url: url,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      page,
      limit,
      es: event_status,
      etid,
      cat_id,
      status: content_type,
      sources: !isGuestMode() ? 'recommendation' : '',
      province,
    },
  })
}

export const sendTicketDeliveryOTP = async ({
  email,
  delivery_method,
  phone_number,
}: {
  email?: string
  delivery_method: 'email' | 'wa' | 'sms'
  phone_number?: string
}) => {
  const isEmail = delivery_method === 'email'
  return await apiClient({
    url: isEmail
      ? '/authentication/guest/v1/booking/otp/send'
      : '/authentication/guest/v1/booking/phone/otp/send',
    method: 'POST',
    data: isEmail
      ? {
          email,
          delivery_method,
          ip: getSessionStorage('ip'),
        }
      : {
          phone_number,
          delivery_method,
          ip: getSessionStorage('ip'),
        },
  })
}

export const verifyTicketDeliveryOTP = async ({ otp }) => {
  return await apiClient({
    url: '/authentication/guest/v1/booking/otp/verify',
    method: 'POST',
    data: {
      otp,
      ip: getSessionStorage('ip'),
    },
  })
}

export const getBookingAccess = async ({ contact_info }) => {
  return await apiClient({
    url: '/authentication/v1/booking/access-token',
    method: 'GET',
    token: getIdToken(),
    params: {
      contact_info,
    },
  })
}
